import './showlessons.css';
import './home.css';
import { useState, useEffect, useRef } from 'react';
import certificate from '../header/images/certificate.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwatchbook, faChevronDown, faLock, faCogs, faClipboardQuestion,  faScroll, faCheck, faLockOpen, faGripLines, faBookOpen, faGavel, faChartLine, faCalculator, faCrown, faClipboardList, faUserGraduate, } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { faWebAwesome } from '@fortawesome/free-brands-svg-icons';
import HeaderLessons from './HeaderLessons';
import LessonProgressLesson from './progresbars/LessonProgressLesson';

export const Showlessons = () => {
  const [showLessonsMain, setShowLessonsMain] = useState(true);
  const [showChapterOne, setShowChapterOne] = useState(false);
  const [showChapterTwo, setShowChapterTwo] = useState(false);
  const [showChapterThree, setShowChapterThree] = useState(false);
  const [showChapterFour, setShowChapterFour] = useState(false);
  const [activePanel, setActivePanel] = useState(null);

  const getPanelKey = (chapter, lessonId) => `chapter-${chapter}-lesson-${lessonId}`;



  const panelRef = useRef(null);
  const lessonButtonRef = useRef(null);

  const unhideLessons = () => setShowLessonsMain(!showLessonsMain);
  const showingChapterOne = () => setShowChapterOne(!showChapterOne);
  const showingChapterTwo = () => setShowChapterTwo(!showChapterTwo);
  const showingChapterThree = () => setShowChapterThree(!showChapterThree);
  const showingChapterFour = () => setShowChapterFour(!showChapterFour);

  const location = useLocation();
  const { updatedProgress } = location.state || {};

  const tothelesson = useNavigate();

  const [lessonProgress, setLessonProgress] = useState({
    1: { unlocked: true, completed: false },
    2: { unlocked: true, completed: false },
    3: { unlocked: false, completed: false },
    4: { unlocked: false, completed: false },
    5: { unlocked: false, completed: false },
  });

  useEffect(() => {
    if (updatedProgress) {
      setLessonProgress(prevProgress => ({
        ...prevProgress,
        ...updatedProgress,
      }));
    }
  }, [updatedProgress]);

  const handleLessonClick = async (chapterId, lessonId) => {
    if (lessonProgress[lessonId]?.unlocked) {
      try {
        // API Call pentru a prelua lecția respectivă
        const response = await fetch(`/api/chapter/${chapterId}/lesson/${lessonId}`);
        if (!response.ok) throw new Error("Eroare la preluarea lecției");
  
        const lessonData = await response.json(); // Parsează lecția primită
  
        // Navigare la pagina lecției cu datele corespunzătoare
        tothelesson('/chaptercontainer', { 
          state: { chapterId, lessonId, lessonData } 
        });
      } catch (error) {
        console.error("Eroare:", error);
        alert("A apărut o problemă la încărcarea lecției.");
      }
    } else {
      alert('Această lecție este blocată. Finalizează lecția anterioară pentru a o debloca.');
    }
  };
  
  

  const togglePanel = (chapterId, lessonId, event) => {
  const panelKey = getPanelKey(chapterId, lessonId);
  setActivePanel(prevPanel => (prevPanel === panelKey ? null : panelKey));
  lessonButtonRef.current = event.currentTarget;

  setTimeout(() => {
    if (panelRef.current && panelKey !== activePanel) {
      panelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, 100);
};


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        panelRef.current && !panelRef.current.contains(event.target) &&
        lessonButtonRef.current && !lessonButtonRef.current.contains(event.target)
      ) {
        setActivePanel(null);
      }
    };

    if (activePanel !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activePanel]);

  const lessons = [
    {
      id: 1,
      explanationType: 'Lectie',
      title: 'Ce este patrimoniul?',
      icon: faCheck,
      leftIcon: faBookOpen,
      type: 'completed'
    },
    {
      id: 2,
      explanationType: 'Lectie',
      title: 'Patrimoniul - perspectiva juridica',
      icon: faLock,
      leftIcon: faGavel,
      type: 'locked'
    },
    {
      id: 3,
      explanationType: 'Lectie',
      title: 'Patrimoniul - perspectiva economica',
      icon: faCrown,
      leftIcon: faChartLine,
      type: 'premium'
    },
    {
      id: 4,
      explanationType: 'Lectie',
      title: 'Rolul contabilitatii',
      icon: faLock,
      leftIcon: faCalculator,
      type: 'locked'
    },
    {
      id: 5,
      explanationType: 'Lectie',
      title: 'Sisteme de contabilitate',
      icon: faCrown,
      leftIcon: faCogs,
      type: 'premium'
    },
    {
      id: 6,
      explanationType: 'Lectie',
      title: 'Contabilitatea in partida simpla',
      icon: faLock,
      leftIcon: faScroll,
      type: 'locked'
    },
    {
      id: 7,
      explanationType: 'Practica', // ← corectat!
      title: 'Practica',
      icon: faCrown,
      leftIcon: faUserGraduate,
      type: 'premium'
    },
    {
      id: 8,
      explanationType: 'Intrebari de test', // ← corectat!
      title: 'Test de capitol',
      icon: faCrown,
      leftIcon: faClipboardQuestion,
      type: 'premium'
    },
  ];
  




  const lessonsReguli = [
    {
      id: 1,
      explanationType: 'Lectie',
      title: 'Cresterile de activ',
      icon: faLockOpen,
      leftIcon: faBookOpen,
      type: 'locked'
    },
    {
      id: 2,
      explanationType: 'Practica',
      title: 'Unde cresc conturile de activ?',
      icon: faCrown,
      leftIcon: faUserGraduate,
      type: 'premium'
    },
    {
      id: 3,
      explanationType: 'Lectie',
      title: 'Cresterile de pasiv',
      icon: faLock,
      leftIcon: faGavel,
      type: 'locked'
    },
    {
      id: 4,
      explanationType: 'Practica',
      title: 'Unde cresc conturile de pasiv?',
      icon: faCrown,
      leftIcon: faUserGraduate,
      type: 'premium'
    },
    {
      id: 5,
      explanationType: 'Lectie',
      title: 'Scaderile de activ',
      icon: faLock,
      leftIcon: faCalculator,
      type: 'locked'
    },
    {
      id: 6,
      explanationType: 'Practica',
      title: 'Unde scad conturile de activ?',
      icon: faCrown,
      leftIcon: faUserGraduate,
      type: 'premium'
    },
    {
      id: 7,
      explanationType: 'Lectie',
      title: 'Scaderile de pasiv',
      icon: faCrown,
      leftIcon: faCogs,
      type: 'premium'
    },
    {
      id: 8,
      explanationType: 'Practica',
      title: 'Unde scad conturile de pasiv?',
      icon: faCrown,
      leftIcon: faUserGraduate,
      type: 'premium'
    },
    {
      id: 9,
      explanationType: 'Intrebari de test',
      title: 'Test de capitol',
      icon: faCrown,
      leftIcon: faClipboardQuestion,
      type: 'premium'
    },
  ];



  const getLessonIconColor = (type) => {
    switch (type) {
      case 'locked':
        return '#9ca3af'; // gri deschis
      case 'premium':
        return '#facc15'; // auriu (galben)
      case 'completed':
        return '#22c55e'; // verde
      default:
        return '#10b981'; // mentă
    }
  };


  const getLessonLeftIconColor = (lesson) => {
    const type = lesson.explanationType || lesson.title;
  
    if (type.toLowerCase().includes('practica')) return 'rgb(59, 130, 246)';
    if (type.toLowerCase().includes('test')) return '#ef4444';
    return '#10b981';
  };
  
  

  

  return (
    <>
      <HeaderLessons />
      <div className='container-show-lessons'>
        <div
          className="colored-header-lesson py-4 rounded-4 d-flex flex-column justify-content-center align-items-center"
          onClick={unhideLessons}
        >
          <div className='d-flex flex-row'>
            <FontAwesomeIcon icon={faSwatchbook} className='dimension-headers-lessons text-white mx-4 p-2 rounded-4' />
            <div className='text-white fs-4 fw-bold mx-1'>Bazele contabilitatii</div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`text-white mx-4 p-2 rounded-4 ${showLessonsMain ? 'dimension-headers-lessons-1' : 'dimension-headers-lessons-rotate'}`}
            />
          </div>
          <div className='text-lesson-presentation'>
           
          </div>
          <LessonProgressLesson />
        </div>

        {showLessonsMain && (
          <div>
            <div className='line-lesson-gackground'>
              <div className='chapter-container' onClick={showingChapterOne}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span className='chapter-customize'>I. Obiectul de studiu al contabilitatii</span>
                <FontAwesomeIcon
                  icon={faGripLines}
                  className={showChapterOne ? 'dbl-line-vertical' : 'dbl-line-horizontal'}
                  style={{ color: 'white', width: '25px', height: '25px' }}
                />
              </div>

              <div className={showChapterOne ? 'displaying-none-chapters' : 'displaying-show-chapters'}>
                {lessons.map((lesson) => (
                  <div key={lesson.id}>
                    <div
                      className='lesson-show-main'
                      onClick={(e) => togglePanel(1, lesson.id, e)}

                    >
                      <div className='symbol-lesson'>
                        <div className='align-items-lessons-inside-show'>
                          <div>
                          <FontAwesomeIcon 
                          icon={lesson.leftIcon} 
                          className='symbol-item-lesson' 
                          style={{ color: getLessonLeftIconColor(lesson) }} 
                        />


                          </div>
                          <div className='items-text-lesson'>
                          {lesson.explanationType && (
                            <span className="lesson-subtitle">{lesson.explanationType}</span>
                          )}
                          <b><span className="lesson-title">{lesson.title}</span></b>
                            
                          </div>
                        </div>
                        <FontAwesomeIcon
                          icon={lesson.icon}
                          className='customize-key-lock'
                          style={{ color: getLessonIconColor(lesson.type) }}
                        />

                      </div>
                    </div>

                    {activePanel === getPanelKey(1, lesson.id) && (
                      <div className="panel-detail-leson" ref={panelRef}>
                        <div className="detail-panel-overlay">
                          <div className="detail-panel-content">
                            <div className='content-panel-details'>
                              <p className='color-show-lesson-text'><b>{lesson.title}</b></p>
                              <div>
                                <button className='button-question-show' onClick={() => handleLessonClick(1, lesson.id)}>Refă întrebările</button>
                              </div>
                              <button
                                className='costomize-start-lesson'
                                onClick={() => handleLessonClick(1, lesson.id)}
                              >
                                Începe lecția
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>



              <div className='chapter-container' onClick={showingChapterTwo}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span className='chapter-customize'>II. Regulile de functionare ale conturilor</span>
                <FontAwesomeIcon
                  icon={faGripLines}
                  className={showChapterTwo ? 'dbl-line-vertical' : 'dbl-line-horizontal'}
                  style={{ color: 'white', width: '25px', height: '25px' }}
                />
              </div>


              <div className={showChapterTwo ? 'displaying-none-chapters' : 'displaying-show-chapters'}>
                {lessonsReguli.map((lesson) => (
                  <div key={lesson.id}>
                    <div
                      className='lesson-show-main'
                      onClick={(e) => togglePanel(2, lesson.id, e)}

                    >
                      <div className='symbol-lesson'>
                        <div className='align-items-lessons-inside-show'>
                          <div>
                          <FontAwesomeIcon 
                            icon={lesson.leftIcon} 
                            className='symbol-item-lesson' 
                            style={{ color: getLessonLeftIconColor(lesson) }} 
                          />

                          </div>
                          <div className='items-text-lesson'>
                          {lesson.explanationType && (
                            <span className="lesson-subtitle">{lesson.explanationType}</span>
                          )}
                          <b><span className="lesson-title">{lesson.title}</span></b>
                          </div>
                        </div>

                        {/* Iconița din dreapta cu animație și culoare pe tip */}
                        <FontAwesomeIcon
                          icon={lesson.icon}
                          className={`customize-key-lock lesson-icon-hover ${lesson.type === 'premium' ? 'icon-pulse' : ''}`}
                          style={{ color: getLessonIconColor(lesson.type) }}
                        />
                      </div>
                    </div>

                    {/* Panel de detalii (dacă lecția este activă) */}
                    {activePanel === getPanelKey(2, lesson.id) && (
                      <div className="panel-detail-leson" ref={panelRef}>
                        <div className="detail-panel-overlay">
                          <div className="detail-panel-content">
                            <div className='content-panel-details'>
                              <p className='color-show-lesson-text'><b>{lesson.title}</b></p>
                              <div>
                                <button className='button-question-show' onClick={() => handleLessonClick(1, lesson.id)}>Refă întrebările</button>
                              </div>
                              <button
                                className='costomize-start-lesson'
                                onClick={() => handleLessonClick(1, lesson.id)}
                              >
                                Începe lecția
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className='chapter-container' onClick={showingChapterThree}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span className='chapter-customize'>III. Contul contabil</span>
                <FontAwesomeIcon
                  icon={faGripLines}
                  className={showChapterThree ? 'dbl-line-vertical' : 'dbl-line-horizontal'}
                  style={{ color: 'white', width: '25px', height: '25px' }}
                />
              </div>

             

              <div className='chapter-container' onClick={showingChapterThree}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span className='chapter-customize'>III. Activele imobilizate</span>
                <FontAwesomeIcon
                  icon={faGripLines}
                  className={showChapterThree ? 'dbl-line-vertical' : 'dbl-line-horizontal'}
                  style={{ color: 'white', width: '25px', height: '25px' }}
                />
              </div>

              

              <div className='chapter-container' onClick={showingChapterFour}>
                <FontAwesomeIcon icon={faLockOpen} style={{ color: 'white', width: '20px', height: '20px' }} />
                <span className='chapter-customize'>IV. Activele circulante</span>
                <FontAwesomeIcon
                  icon={faGripLines}
                  className={showChapterFour ? 'dbl-line-vertical' : 'dbl-line-horizontal'}
                  style={{ color: 'white', width: '25px', height: '25px' }}
                />
              </div>


            </div>
          </div>
        )}

        <div className='obtain-certificate'>
          <img src={certificate} alt="certificate" className="disabled-image" />
          <h4>Obtine diploma de bazele contabilitii</h4>
          <p>Te descurci bine, continua pana cand optii diploma!</p>
          <button className='buton-diploma'>Revendica-ti diploma</button>
        </div>
        <Link to='/diploma'>Diploma</Link>
      </div>
    </>
  );
};
