import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import StatusProgres from './StatusProgres';
import './chaptercontainer.css';
import finishLesson from './imagesHome/finish_lesson.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import levelUp from './header/images/level-up.mp3';
import error from './header/images/error.mp3';


  // Adaugă URL-ul API-ului folosind variabila de mediu
  const apiUrl = process.env.REACT_APP_API_URL;



  // Adaugă această funcție pentru a iniția vibrația pe dispozitivele compatibile
const triggerVibration = (pattern) => {
  if (navigator.vibrate) {
    navigator.vibrate(pattern);
  }
};



const Chaptercontainer = () => {


  

  const explanationRef = useRef(null);

  const [coinCount, setCoinCount] = useState(0);
  const [showHeartPopup, setShowHeartPopup] = useState(false);

  const handleShowHeartPopup = () => {
    setShowHeartPopup(true);
  };
  
  const closeHeartPopup = () => {
    setShowHeartPopup(false);
  };



  const [showEyePopup, setShowEyePopup] = useState(false);

const handleShowEyePopup = () => {
  setShowEyePopup(true);
};

const closeEyePopup = () => {
  setShowEyePopup(false);
};


    // Componenta Pop-up pe care o vom trimite ca prop
    const HeartPopup = ({ onClose }) => {
      return (
        <div className="heart-popup-overlay">
          <div className="heart-popup-box">
            <h3 className="heart-popup-title">Cumpara vieti</h3>
            <p className="heart-popup-message">Foloseste monedele acumulate pentru obtine mai multe vieti!</p>
            <p>Sold monede: 45</p>
            <h4>Cumpara vieti</h4>
            <button onClick={onClose} className="heart-popup-button">Continua</button>
          </div>
        </div>
      );
    };


    const EyePopup = ({ onClose }) => {
      return (
        <div className="eye-popup-overlay">
          <div className="eye-popup-box">
            <h3 className="eye-popup-title">Vezi raspunsul corect</h3>
            <div className="eye-popup-message">
              <p>Foloseste monedele acumulate pentru a vedea rasppunsul corect!</p>
              <p>Sold monede: 45</p>

            </div>
            <button onClick={onClose} className="eye-popup-button">Vezi raspunsul {'(20)'}</button>
          </div>
        </div>
      );
    };
    
    

  // Componentă pentru alerta personalizată
const CustomAlert = ({ message, onConfirm, onCancel }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.alertBox}>
        {message}
        <div style={styles.buttonContainer}>
          <button onClick={onConfirm} style={styles.button}>Da</button>
          <button onClick={onCancel} style={styles.button}>Nu</button>
        </div>
      </div>
    </div>
  );
};






const [showAlert, setShowAlert] = useState(false);
const navigate = useNavigate(); // Inițializează navigate

const handleExitClick = () => {
  setShowAlert(true);
};

const handleConfirmExit = () => {
  setShowAlert(false);
  navigate('/showlessons'); // Redirecționează către pagina /showlessons
};

const handleCancelExit = () => {
  setShowAlert(false);
};

const messageAlertClose = 
<div>
  <h3>Nu renunța!</h3>
  <p>Dacă părăsești această lecție, vei pierde progresul. Ești sigur?</p>
</div>;






  const location = useLocation();
  const { chapterId, lessonId } = location.state || {}; // Primește chapterId și lessonId
  const [lessonData, setLessonData] = useState(null);
  const [nextQuestion, setNextQuestion] = useState(0);

  const [isLessonFinished, setIsLessonFinished] = useState(false); // Stare pentru finalizarea lecției


  const [changeColorButton, setChangeColorButton] = useState(false);




  //stateuri pentru raspunsul corect

  const [simpleCorrectAnswer, setSimpleCorrectAnswer] = useState(false)




  // Funcția pentru a finaliza lecția și redirecționare

  const navigateToShowLesson = useNavigate();
  const finalizeLesson = () => {

    const lessonProgress = JSON.parse(localStorage.getItem('lessonProgress')) || {};

    const updatedProgress = {
      ...lessonProgress,
      [lessonId]: { ...lessonProgress[lessonId], completed: true },
      [lessonId + 1]: { ...lessonProgress[lessonId + 1], unlocked: true },
    };

    // Salvează progresul actualizat în localStorage
    localStorage.setItem('lessonProgress', JSON.stringify(updatedProgress));

    // Navighează înapoi la Showlessons și trimite progresul prin state
    navigateToShowLesson('/showlessons',{ state: { updatedProgress } }); // Redirecționează la LessonContainer
  };


//functia pentru calcularea progresului

const calculateProgress = () => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return 0; // Dacă datele nu sunt încă încărcate, progresul este 0%
  }

  const totalQuestions = lessonData.questions.length - 1; // Ne asigurăm că progresul ajunge la 100% la penultima întrebare

  const progress = (nextQuestion / totalQuestions) * 100; // Calculăm procentajul progresului

  return Math.min(progress, 100); // Ne asigurăm că nu depășește 100%
};







  //state pentru setarea mesajului

    // State pentru a stoca răspunsurile mutate
    const [dataReceived, setDataReceived] = useState([]);

 
// Funcția pentru a muta sau elimina răspunsul selectat
const toggleItem = (answer) => {
  setDataReceived((prev) => {
    let updatedData;
    
    if (prev.includes(answer)) {
      // Dacă răspunsul este deja mutat, îl eliminăm din dataReceived
      updatedData = prev.filter(item => item !== answer);
    } else {
      // Dacă răspunsul nu este mutat, îl adăugăm
      updatedData = [...prev, answer];
    }

    // Activăm sau dezactivăm butonul în funcție de conținutul array-ului dataReceived
    setDesableButton(updatedData.length === 0);  // Dezactivat dacă e gol, activat altfel
    setTextButton('Verifica');
    
    setChangeColorButton(true);


    return updatedData;
  });
};

// Verificăm dacă avem deja un răspuns mutat pentru acel index
const renderAnswer = (index) => {
  const code = dataReceived[index];
  const currentQuestion = lessonData.questions[nextQuestion];

  if (!code || !currentQuestion || !currentQuestion.options) return '';

  const option = currentQuestion.options.find(opt => opt.code === code);
  return option ? option.label : code;
};

     
 


const toNextQuestion = () => {
  setNextQuestion((prev) => {
    const newNextQuestion = prev + 1;
    if (newNextQuestion >= lessonData.questions.length) {
      console.log("Lesson is finished!");
      setIsLessonFinished(true);
    }
    setAnswerStatus(null);
    return newNextQuestion;
  });
};

useEffect(() => {
  if (explanationRef.current) {
      explanationRef.current.scrollBy({
          top: 1, // Face un mic scroll de 1 pixel
          behavior: 'smooth' // Scroll lin
      });
  }
}, [nextQuestion]); // Declanșăm scroll-ul de fiecare dată când se schimbă întrebarea



  useEffect(() => {
    if (chapterId && lessonId) {
      // Cererea fetch pentru lecția selectată
      fetch(`${apiUrl}/chapter/${chapterId}/lesson/${lessonId}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache"
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('A apărut o eroare');
        }
        return response.json();
      })
      .then(data => {
        setLessonData(data);
      })
      .catch(err => {
        console.error(`Error: ${err}`);
      });
    }
  }, [chapterId, lessonId]);


// Folosim un string (sau null) pentru simpleSelectType, deoarece utilizatorul selectează o singură opțiune
const [simpleSelectType, setSimpleSelectType] = useState(null); //usestateul pentru stocarea raspusurilor simple select
const [userInput, setUserInput] = useState({});  // Răspunsul introdus de utilizator
const [selectedAnswers, setSelectedAnswers] = useState([]); // Răspunsurile bifate de utilizator
const [message, setMessage] = useState('');
const [textButton, setTextButton] = useState('')

useEffect(() => {
  if(isLessonFinished) {
  setTextButton('Finalizeaza lectia');

} else {
  setTextButton('ContinuaF')
}
  
}, [isLessonFinished])


const [isAnswerChecked, setIsAnswerChecked] = useState(false); //urmaresc daca raspunsul a fost verificat


const buttonTextChange = () => {

  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return <p>Loading lesson...</p>;
  }
  const currentQuestion = lessonData.questions[nextQuestion]; 

  switch (currentQuestion.type) {
    case 'explanationLesson':
      setTextButton('Continua00000');
      break;

    case 'simpleSelect':
      setTextButton('vaerifica 1')
      setChangeColorButton(true)
      break;

    case 'manualInput':
      
      break;

    case 'dragAndDrop':
      setTextButton('Verifica');
     
      
      break;

      case 'dragAndDropHorizontal':
        setTextButton('Verifica');
       
        
        break;


    case 'multipleSelect':
      setTextButton('Verifica')
     
      break;

    default:
      
  }
};


const [desableButon, setDesableButton] = useState(false);
//functia pentru dezactivarea butonului






useEffect(() => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
      return;
  }

  const currentQuestion = lessonData.questions[nextQuestion];

  // Resetare stări pentru fiecare nouă întrebare
  setIsAnswerChecked(false);
  setSimpleSelectType(null);
  setMessage('');
  setSimpleCorrectAnswer(false);  // Ascunde răspunsul corect

  // Dacă tipul întrebării necesită selecție, dezactivează butonul
  if (['simpleSelect', 'manualInput', 'dragAndDrop', 'dragAndDropHorizontal', 'multipleSelect'].includes(currentQuestion.type)) {
      setDesableButton(true);
  } else {
      // Activați butonul pentru explanationLesson și lessonResume
      setDesableButton(false);
  }
}, [nextQuestion, lessonData]);


const [answerStatus, setAnswerStatus] = useState(null); // Starea pentru corect/greșit/null

// Adăugăm o stare pentru a controla afișarea răspunsului corect
const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  //functia de verificare a raspunsurilor
  
  const verifyAnswer = () => {

       if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
      return <p>Loading lesson...</p>;
    }
    const currentQuestion = lessonData.questions[nextQuestion]; 


    switch (currentQuestion.type) {
      case 'explanationLesson':
        setDesableButton(false); // Activați butonul pentru acest tip
        toNextQuestion();
        break;
      case 'simpleSelect':
          if (!isAnswerChecked) {
              setDesableButton(true); // Dezactivați butonul la începutul întrebării
              if (currentQuestion.correctAnswer === simpleSelectType) {
                  setMessage('Răspuns corect!');
                  setAnswerStatus('correct');
                  const audioUp = new Audio(levelUp);
                  audioUp.play();
                  setCoinCount(prev => prev + 1);
                  triggerVibration(200);
                  setDesableButton(false); // Activează butonul după verificarea răspunsului
              } else {
                  setMessage('Vezi raspunsul!');
                  setAnswerStatus('incorrect');
                  const audioErr = new Audio(error);
                  audioErr.play();
                  setSimpleCorrectAnswer(currentQuestion.correctAnswer);
                  triggerVibration([300, 100, 300]);
                  setDesableButton(false);
              }
              setIsAnswerChecked(true);
              setTextButton('Continua');
              setShowCorrectAnswer(false);
              setChangeColorButton(false);
          } else {
              // Trecem la următoarea întrebare
              setIsAnswerChecked(false);
              setSimpleSelectType(null);
              setMessage('');
              setTextButton('Verifică');
              toNextQuestion();
              setShowCorrectAnswer(false);
          }
          break;
      case 'manualInput':
        if (!isAnswerChecked) {
          // Verificăm dacă toate răspunsurile sunt corecte
          let allCorrect = true;
          currentQuestion.correctAnswer.forEach((answer, index) => {
            // Comparam răspunsul utilizatorului cu codul corect
            if (Number(userInput[index]) !== answer.code) {
              allCorrect = false;
            }
          });
          if (allCorrect) {
            setMessage('Răspuns corect!');
            setAnswerStatus('correct');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
            // Vibrație scurtă pentru răspuns corect
          triggerVibration(200); // 200ms pentru un răspuns corect
          } else {
            setMessage('Vezi raspunsul!');
            setAnswerStatus('incorrect');
            const audioErr = new Audio(error);
            audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer.map((newAnswer) => {
             return <div>{newAnswer.code}:{newAnswer.label}</div>
            }));
            triggerVibration([300, 100, 300]);
          }
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă1');  // Schimb textul butonului la "Continuă"
          setChangeColorButton(false)
          setDesableButton(false); // Activăm butonul după verificare pentru a permite utilizatorului să continue
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setUserInput({});  // Resetează input-urile utilizatorului
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă2');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
          toNextQuestion();  // Trecem la următoarea întrebare
        }
        break;
      case 'dragAndDrop':
        if (!isAnswerChecked) {
          // Verificăm dacă răspunsurile mutate de utilizator sunt corecte
          if (JSON.stringify(dataReceived) === JSON.stringify(currentQuestion.correctAnswer)) {
            setMessage('Răspuns corect!');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
            triggerVibration(200);
          } else {
            setMessage('Vezi raspunsul!');
            setAnswerStatus('incorrect');
            const audioErr = new Audio(error);
            audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer.map((newAnswer) => {
              return <div>{newAnswer}</div>
             }));
            // Vibrație mai lungă pentru răspuns greșit
          triggerVibration([300, 100, 300]); // Vibrație cu pauză pentru răspuns greșit
          }
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă6');  // Schimb textul butonului la "Continuă"
          setChangeColorButton(false);
            // Activez butonul pentru a permite trecerea la următoarea întrebare
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setDataReceived([]);  // Resetează răspunsurile mutate
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă5');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
            // Dezactivează butonul pentru a forța verificarea înainte de a continua
          toNextQuestion();  // Trecem la următoarea întrebare
        }
        break;

        case 'dragAndDropHorizontal':
        if (!isAnswerChecked) {
          const userAnswers = dataReceived.filter(item => item !== null && item !== undefined);

          if (JSON.stringify(userAnswers) === JSON.stringify(currentQuestion.correctAnswer.slice(1))) {
            setMessage('Răspuns corect!');
            const audioUp = new Audio(levelUp);
            audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
            triggerVibration(200);
            setAnswerStatus('correct');
          } else {
            setMessage('Vezi raspunsul!');
            setAnswerStatus('incorrect');
            const audioErr = new Audio(error);
            audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer.slice(1).map((item, idx) => (
              <div key={idx}>{item}</div>
            )));
            triggerVibration([300, 100, 300]);
          }

          setIsAnswerChecked(true);
          setTextButton('Continuă');
          setChangeColorButton(false);
        } else {
          setIsAnswerChecked(false);
          setDataReceived([null, null]);  // Resetăm în formatul nou
          setMessage('');
          setTextButton('Verifică');
          setChangeColorButton(false);
          toNextQuestion();
        }
        break;


      case 'multipleSelect':
        if (!isAnswerChecked) {
          // Verificăm dacă răspunsurile selectate de utilizator sunt corecte
          const sortedSelected = [...selectedAnswers].sort();  // Sortăm o copie pentru comparație
          const sortedCorrect = [...currentQuestion.correctAnswer].sort();  // Sortăm răspunsurile corecte
          
          if (JSON.stringify(sortedSelected) === JSON.stringify(sortedCorrect)) {
            setMessage('Răspuns corect!');
            setAnswerStatus('correct');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
            triggerVibration(200);
          } else {
            setMessage('Vezi raspunsul!');
            setAnswerStatus('incorrect');
            const audioErr = new Audio(error);
                audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer);
            triggerVibration([300, 100, 300]);
          }
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă4');  // Schimb textul butonului la "Continua"
          setChangeColorButton(false);
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setSelectedAnswers([]);  // Resetează răspunsurile selectate
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă3');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
          toNextQuestion();  // Trecem la următoarea întrebare
        }
        break;
        case 'lessonResume':

      
         
          setIsLessonFinished(true)
        
        break;
      default:
       <p>'Tipul întrebării nu este recunoscut'</p> 
    }
  };
  

  const handleInputChange = (index, value) => {
    const updatedInput = {
      ...userInput,
      [index]: value  // Actualizăm răspunsul pentru fiecare index
    };
    
    setUserInput(updatedInput);

   
  
    // Verificăm dacă s-au introdus date în cel puțin un câmp de input
    const hasData = Object.values(updatedInput).some(val => val.trim() !== '');
    
    // Schimbăm textul butonului dacă există input
    if (hasData) {
      setTextButton('Verifică888888888');
    
      setChangeColorButton(true);
    } else {
      setTextButton('Continua12');
    }
  };
 



// Funcția pentru a actualiza răspunsurile selectate
const handleCheckboxChange = (option) => {
  setSelectedAnswers(prevSelected => {
    let updatedSelected;
    
    if (prevSelected.includes(option)) {
      // Dacă opțiunea este deja selectată, o eliminăm din array
      updatedSelected = prevSelected.filter(answer => answer !== option);
    } else {
      // Adăugăm opțiunea selectată
      updatedSelected = [...prevSelected, option];
    }
    
    // Verificăm dacă mai sunt opțiuni selectate
    setDesableButton(updatedSelected.length === 0); // Dezactivează butonul dacă nu e nimic selectat
    return updatedSelected; // Returnează lista actualizată de răspunsuri selectate
  });

  // După fiecare schimbare, setăm textul butonului la "Verifică"
  setTextButton('Verifică0000000000');
 
  setChangeColorButton(true);
};

const [finalMessage, setFinalMessage] = useState(false);

useEffect(() => {
  if (isLessonFinished) {
    setFinalMessage(true); // Setează mesajul de final instant, fără întârziere
  }
}, [isLessonFinished]);



const areAllFieldsCompleted = useCallback(() => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return false;
  }

  const currentQuestion = lessonData.questions[nextQuestion];

  if (!currentQuestion) return false;

  switch (currentQuestion.type) {
    case 'dragAndDrop':
      return dataReceived.length === currentQuestion.correctAnswer.length &&
             currentQuestion.correctAnswer.every(answer => dataReceived.includes(answer));
    case 'dragAndDropHorizontal':
      return dataReceived.every(val => val !== null && val !== undefined);
    default:
      return true;
  }
}, [lessonData, nextQuestion, dataReceived]);



useEffect(() => {
  if (lessonData && lessonData.questions && lessonData.questions[nextQuestion]?.type === 'dragAndDropHorizontal') {
    setDataReceived([null, null]); // în loc de []
  }
}, [nextQuestion, lessonData]);



useEffect(() => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return;
  }

  const currentQuestion = lessonData.questions[nextQuestion];

  switch (currentQuestion.type) {
    case 'simpleSelect':
      // Dezactivăm butonul dacă nu s-a selectat încă un răspuns
      setDesableButton(simpleSelectType === null);
      break;

    case 'multipleSelect':
      // Dezactivăm butonul dacă nu s-a selectat încă un răspuns
      setDesableButton(selectedAnswers.length === 0);
      break;

    case 'manualInput':
      // Verificăm dacă toate câmpurile de input sunt completate
      const allFilled = currentQuestion.correctAnswer.every((_, idx) => {
        return userInput[idx] && userInput[idx].trim() !== '';
      });
      setDesableButton(!allFilled);
      break;

    case 'dragAndDrop':
      // Verificăm dacă toate elementele au fost mutate corect
      setDesableButton(!areAllFieldsCompleted());
      break;

      case 'dragAndDropHorizontal':
      // Verificăm dacă toate elementele au fost mutate corect
      setDesableButton(!areAllFieldsCompleted());
      break;

    default:
      // Activăm butonul pentru explanationLesson și lessonResume
      setDesableButton(false);
      break;
  }
}, [simpleSelectType, selectedAnswers, userInput, dataReceived, nextQuestion, lessonData, areAllFieldsCompleted]);









// Afișarea în funcție de tipul întrebării
const renderContent = () => {
   
  if (isLessonFinished && finalMessage) {
    return null; // Ascunde rezumatul când lecția este completă
  }


    if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
      return <p>Loading lesson...</p>;
    }





    const currentQuestion = lessonData.questions[nextQuestion];


  
   
    switch (currentQuestion.type) {

        case 'explanationLesson':
        return (
          <div ref={explanationRef} className='explanation-lesoon-container-text'>
              
              <div className='description-lesson-text-explanation'>
                  {currentQuestion.descriptionExplanation.map((explanation, index) => (
                      <div
                          key={index}
                          className='my-3 text-size-emplanation'
                          dangerouslySetInnerHTML={{ __html: explanation }}
                      ></div>
                  ))}
              </div>
          </div>
 
        );

      case 'simpleSelect':
        return (
          <div className='explanation-lesoon-container'>
           
            <p className='tittle-sescrition-lesson title-simple-select-align'><p>{currentQuestion.question}</p> </p>
            <div className='simple-select-buttons-description'>
    {currentQuestion.options.map((option, index) => {
        let className = 'simple-Button-select';
        
        // Aplica stilul doar pe răspunsul selectat de utilizator
        if (isAnswerChecked && option === simpleSelectType) {
            if (answerStatus === 'correct') {
                className = 'simple-Button-select-correct'; // Verde pentru corect
            } else if (answerStatus === 'incorrect') {
                className = 'simple-Button-select-incorrect'; // Roșu pentru greșit
            }
        } else if (simpleSelectType === option && !isAnswerChecked) {
            // Stil normal de selectare înainte de verificare
            className = 'simple-Button-select-colored';
        }

        return (
            <button 
                key={index} 
                className={className}
                onClick={() => { setSimpleSelectType(option); buttonTextChange(); setDesableButton(false); }}
                disabled={isAnswerChecked}
            >
                <p>{option}</p>
            </button>
        );
    })}
</div>


            
          </div>
        );


        
        case 'manualInput':
          return (
            <div className='explanation-lesoon-container'>
              <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.question}</strong></h4>
        
              <div className='align-input-items'>
                {currentQuestion.correctAnswer.map((answer, index) => {
                  const isCorrect = Number(userInput[index]) === answer.code; // Verifică dacă răspunsul este corect
                  const inputClass = isAnswerChecked
                    ? isCorrect
                      ? 'answer-input-correct'
                      : 'answer-input-incorrect'
                    : 'answer-input-item-complete';
        
                  return (
                    <div className='' key={index}>
                      <div className='input-item-answer-type'>
                        <input
                          className={inputClass}
                          type="number"
                          maxLength='4'
                          value={userInput[index] || ''}
                          onChange={(e) => { 
                            handleInputChange(index, e.target.value); 
                            setDesableButton(false);
                          }}
                          disabled={isAnswerChecked}
                        />
                        <p className='input-title-element-answer'>{answer.label}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );


          

          case 'dragAndDrop': {
            const areAllFieldsCompletedLocal = () => {
              // Verificăm dacă toate câmpurile au fost completate și sunt corecte
              return currentQuestion.options.every((_, index) => {
                return dataReceived[index] && currentQuestion.correctAnswer.includes(dataReceived[index]);
              });
            };
          
            const handleDragItemClick = (answer) => {
              if (!dataReceived.includes(answer)) {
                toggleItem(answer);
              }
            };
          
            const handleReset = () => {
              setDataReceived([]);
            };
          
            const handleUndo = () => {
              setDataReceived((prev) => {
                const updatedData = [...prev];
                const lastFilledIndex = updatedData.findIndex(item => item === undefined) - 1;
                if (lastFilledIndex >= 0) {
                  updatedData[lastFilledIndex] = undefined;
                } else {
                  updatedData.pop();
                }
                return updatedData;
              });
            };
          
            return (
              <div className='explanation-lesoon-container'>
                <h4 className='tittle-sescrition-lesson'>
                  <strong>{currentQuestion.question}</strong>
                </h4>
          
                {/* Câmpuri pentru răspunsuri */}
                <div className='cadru-lesson-grag-drop'>
                  {currentQuestion.options.map((option, index) => (
                    <button
                      key={index}
                      className='drag-lesson-answer'
                      disabled={index !== dataReceived.length}
                      onClick={() => dataReceived[index] && toggleItem(dataReceived[index], index)}
                    >
                      <div className='drag-option'>
                        {renderAnswer(index)}
                      </div>
                      <div className='drag-text'>
                        <p>{option.label}</p>
                      </div>
                    </button>
                  ))}
                </div>
          
                {/* Butoanele de răspuns disponibile */}
                <div className='drag-background-options'>
                  {currentQuestion.correctAnswer.map((answer, index) => (
                    <button
                      key={index}
                      className='buttons-drag-options'
                      onClick={() => handleDragItemClick(answer)}
                      disabled={dataReceived.includes(answer)}
                    >
                      <div>{answer}</div>
                    </button>
                  ))}
                </div>
          
                {/* Butoane de resetare și undo */}
                <div className='drag-controls'>
                  <button className='control-button' onClick={handleReset}>
                    <FontAwesomeIcon icon={faRefresh} size="2x" />
                  </button>
                  <button className='control-button' onClick={handleUndo}>
                    <FontAwesomeIcon icon={faDeleteLeft} size="2x" />
                  </button>
                </div>
          
                {/* Butonul de verificare */}
                <div className='position-button-lesson'>
                  <button
                    className={`button-next-quiz ${areAllFieldsCompletedLocal() ? '' : 'button-next-quiz-desabled'}`}
                    onClick={verifyAnswer}
                    disabled={!areAllFieldsCompletedLocal()}
                  >
                    Verifică
                  </button>
                </div>
              </div>
            );
          }
          case 'dragAndDropHorizontal': {
            const areAllFieldsCompletedLocal = () => {
              return dataReceived.every(val => val !== null && val !== undefined);
            };
          
            const handleDragItemClick = (code) => {
              if (dataReceived.includes(code)) return;
          
              const emptyIndex = dataReceived.findIndex(val => val === null || val === undefined);
              if (emptyIndex !== -1) {
                const updated = [...dataReceived];
                updated[emptyIndex] = code;
                setDataReceived(updated);
              }
            };
          
            const handleBoxClick = (index) => {
              const updated = [...dataReceived];
              updated[index] = null;
              setDataReceived(updated);
            };
          
            const handleReset = () => {
              setDataReceived([null, null]);
            };
          
            const handleUndo = () => {
              const updated = [...dataReceived];
              const lastIndex = updated.map((v, i) => v ? i : -1).filter(i => i !== -1).pop();
              if (lastIndex !== undefined) {
                updated[lastIndex] = null;
                setDataReceived(updated);
              }
            };
          
            return (
              <div className='explanation-lesoon-container'>
                <h4 className='tittle-sescrition-lesson'>
                  <strong>{currentQuestion.question}</strong>
                </h4>
          
                {/* Ecuația pe orizontală */}
                <div className='horizontal-equation-layout'>
                  <div className='equation-box-static'>
                    {currentQuestion.correctAnswer[0]}
                  </div>
                  <div className='equation-sign'> = </div>
          
                  {[0, 1].map((i) => (
                    <div
                      key={i}
                      className='equation-box cursor-pointer'
                      onClick={() => handleBoxClick(i)}
                    >
                      {dataReceived[i] || ''}
                    </div>
                  ))}
                </div>
          
                {/* Zona de opțiuni și butoane */}
                <div className='position-button-lesson'>
                  {/* Opțiunile de răspuns */}
                  <div className='horizontal-options'>
                  {currentQuestion.options
                    .filter(opt => opt.code !== currentQuestion.correctAnswer[0])
                    .map((option, index) => {
                      const isUsed = dataReceived.includes(option.code);

                      return (
                        <button
                          key={index}
                          className={`equation-option-btn ${isUsed ? 'placeholder-btn' : ''}`}
                          onClick={() => handleDragItemClick(option.code)}
                          disabled={isUsed}
                        >
                          {option.code}
                        </button>
                      );
                    })}
                  </div>
          
          
                  {/* Buton Verifică */}
                  <button
                    className={`button-next-quiz ${areAllFieldsCompletedLocal() ? '' : 'button-next-quiz-desabled'}`}
                    onClick={verifyAnswer}
                    disabled={!areAllFieldsCompletedLocal()}
                  >
                    Verifică
                  </button>
                </div>
              </div>
            );
          }
          
   

          
          
          
          

        case 'multipleSelect':
          return (
            <div className='explanation-lesoon-container'>
              <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.question}</strong></h4>
              <div className='multiple-answer-lessons'>
                {currentQuestion.options.map((option, index) => {
                  const isSelected = selectedAnswers.includes(option);  // Verificăm dacă opțiunea este selectată
                  const isCorrect = currentQuestion.correctAnswer.includes(option);  // Verificăm dacă opțiunea este corectă
        
                  // Aplicați clase CSS dinamice în funcție de starea răspunsului selectat după verificare
                  let checkboxClass = 'check-box-multiple-answer'; // Stilul implicit
                  let labelClass = '';
        
                  if (isAnswerChecked && isSelected) {
                    if (isCorrect) {
                      checkboxClass = 'checkbox-correct'; // Verde pentru răspunsurile selectate corect
                      labelClass = 'label-correct';
                    } else {
                      checkboxClass = 'checkbox-incorrect'; // Roșu pentru răspunsurile selectate greșit
                      labelClass = 'label-incorrect';
                    }
                  }
        
                  return (
                    <div 
                      key={index} 
                      className={`align-multiple-items ${
                        isSelected && !isAnswerChecked ? 'border-selected' : ''
                      } ${
                        isAnswerChecked && isSelected
                          ? isCorrect
                            ? 'border-correct'
                            : 'border-incorrect'
                          : ''
                      }`}
                      onClick={() => !isAnswerChecked && handleCheckboxChange(option)} // Selectează când se dă click pe tot cadrul
                    >
                      <div>
                        <input
                          className={checkboxClass}
                          type="checkbox"
                          id={`option-${index}`}
                          checked={isSelected} 
                          readOnly
                        />
                      </div>
                      <h6 htmlFor={`option-${index}`} className={`${labelClass} cursor-pointer`}>
                        {option}
                      </h6>
                    </div>
                  );
                })}
              </div>
            </div>
          );

        case 'lessonResume':
          if (finalMessage) {
            return null; // Nu afișează nimic dacă lecția este finalizată
          }
          return (
            <div className='explanation-lesoon-container-resume'>
              <h4 className='tittle-sescrition-lesson-explanation'><strong>{currentQuestion.titleExplanation}</strong></h4>
              <div>
                {currentQuestion.descriptionExplanation.map((description, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>                
                ))}
              </div> 
            </div>
          );

      default:
        return <p>Tipul întrebării nu este recunoscut.</p>;
    }

    

  };



  

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
  
      {/* Bara de progres */}
      <div className='position-progress-bar'>
        {!isLessonFinished && (
          <StatusProgres 
            width={calculateProgress()}
            coinCount={coinCount}
            onExitClick={handleExitClick}
            onHeartClick={handleShowHeartPopup} // trimitem funcția de activare a pop-up-ului
            showHeartPopup={showHeartPopup}
            onEyeClick={handleShowEyePopup} 
          />
        )}
      </div>
  
      {/* Conținutul principal al lecției - păstrat vizibil indiferent de starea isLessonFinished */}
      <div className='position-main-lesson'>
        {renderContent()}
  
        {showHeartPopup && <HeartPopup onClose={closeHeartPopup} />}
        {showEyePopup && <EyePopup onClose={closeEyePopup} />}
      </div>
  
      {/* Mesajul de finalizare al lecției */}
      {finalMessage && (
        <div className='final-message-lesson-style'>
          <img src={finishLesson} alt='finish' className='my-3' style={{ width: '60%' }} />
          <h1 className='my-3 fw-bold fs-1'>Lecție completă!</h1>
        </div>
      )}
  
      {/* Secțiunea butonului */}
      <div className='position-button-lesson'>
        {showCorrectAnswer && (
          <p className='answer-show-after-button'>
            {isAnswerChecked && simpleCorrectAnswer}
          </p>
        )}
  
        {answerStatus === 'incorrect' && (
          <button 
            className='next-message-answer-wrong'
            onClick={() => setShowCorrectAnswer(true)} // Afișează răspunsul corect
          >
            {message}
          </button>
        )}
  
        {/* Butonul care se schimbă între "Continuă" și "Finalizează lecția" */}
        <button
          className={
            desableButon
              ? 'button-next-quiz-desabled'
              : answerStatus === 'correct'
              ? 'button-next-quiz-correct'
              : answerStatus === 'incorrect'
              ? 'button-next-quiz-incorrect'
              : changeColorButton
              ? 'button-next-quiz-verify'
              : 'button-next-quiz'
          }
          onClick={() => {
            if (isLessonFinished && finalMessage) {
              finalizeLesson(); // Asigură-te că finalizezi lecția după afișarea mesajului final
            } else {
              verifyAnswer();
            }
          }}
          disabled={desableButon}
        >
          {isLessonFinished && finalMessage ? "Finalizează lecția" : textButton}
        </button>
      </div>
  
      {/* Alerta de părăsire a lecției */}
      {showAlert && (
        <CustomAlert
          message={messageAlertClose}
          onConfirm={handleConfirmExit}
          onCancel={handleCancelExit}
        />
      )}
    </div>
  );
  



  
};



const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    pointerEvents: 'auto',
  },
  alertBox: {
    backgroundColor: 'white',
    padding: '40px',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    margin: '0 30px',
    zIndex: 1100,
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
    margin: '0 auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: 'rgb(22, 199, 158)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};


export default Chaptercontainer;